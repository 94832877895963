// Cuntbox

$(document).ready(function() {
    var meetUsElement = $('.count-box');
    var hasStartedCounting = false;

    function isElementInViewport(element) {
        var elementTop = element.offset().top;
        var elementBottom = elementTop + element.height();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }

    function startCounting() {
        if (isElementInViewport(meetUsElement) && !hasStartedCounting) {
            hasStartedCounting = true;
            $('.count').each(function() {
                var textValue = $(this).text().trim();
                if (!isNaN(parseFloat(textValue)) && !textValue.includes('.')) {
                    var targetValue = parseFloat(textValue);
                    $(this).prop('Counter', 0).animate({
                        Counter: targetValue
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function(now) {
                            $(this).text(Math.ceil(now));
                        }
                    });
                } else if (textValue.includes('.') && !isNaN(parseFloat(textValue))) {
                    var targetValue = parseFloat(textValue);
                    $(this).prop('Counter', 0).animate({
                        Counter: targetValue
                    }, {
                        duration: 2000,
                        easing: 'swing',
                        step: function(now) {
                            $(this).text(now.toFixed(1));
                        }
                    });
                }
            });
        }
    }

    $(window).on('scroll', function() {
        startCounting();
    });

    startCounting();
});
