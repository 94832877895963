function showPopup() {
    var popup = document.getElementById("popup-1");
    popup.style.display = "block";
    document.body.classList.add("no-scroll");
  }


  function cancel() {
    var popup = document.getElementById("popup-1");
    popup.style.display = "none";
    document.body.classList.remove("no-scroll");
  }
