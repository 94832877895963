

    $('#function__slider').owlCarousel({
      items: 7,
      lazyLoad:true,
      loop:true,
      responsive: {
        0: {
          items: 2,
        },
        768: {
          items: 4,
        },
        992: {
          items: 5,
        }
      },
      dots: true
    });


    $('.about__carousel').owlCarousel({
      items: 6,
      lazyLoad:true,
      loop:true,
      margin:20,
      responsive: {
        0: {
          items: 1.3,
        },
        768: {
          items: 3,
        },
        950: {
          items: 4,
        },
        1250: {
          items: 5,
        }
      },
      dots: false
    });




